export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.scroll__to[href*="#"]:not([href="#"])').on('click',function (e) {
            e.preventDefault();
            let offset = 0;
            let target = this.hash;
            if ($(this).data('offset') != undefined) {
                offset = $(this).data('offset');
            }
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset,
            }, 800, 'swing', function () {
                // window.location.hash = target;
            });
        });


        $(window).on('load', function () {
            $('.vacancy__steps__slider').slick({
                dots: false,
                infinite: false,
                arrows: false,
                speed: 300,
                centerMode: false,
                slidesToShow: 5,
                slidesToScroll: 1,
                swipeToSlide: true,
                responsive: [
                    {
                        breakpoint: 1799,
                        settings: {
                            slidesToShow: 4,
                        },
                    },
                    {
                        breakpoint: 1499,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ],
            });
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
