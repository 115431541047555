export const singleVacaturesData = {
    init() {
        // JavaScript to be fired on all pages

        const writeBtn = document.querySelector('.vacancy__right__copy');

        writeBtn.addEventListener('click', () => {
            writeBtn.classList.add('open');
            setTimeout(function () {
                writeBtn.classList.remove('open');
            }, 2000);

            // getting the text content that we want to copy
            const content = window.location.href;
            // loading the content into our clipboard
            navigator.clipboard.writeText(content);
        })


        $('.vacancy__row__title').each(function () {
            let $extra_height = 40;
            if ($(window).width() < 575) {
                $extra_height = 30;
            }
            let $titleHeight = $(this).height() + $extra_height;
            $(this).closest('.vacancy__content__row').css({
                'max-height': $titleHeight,
            })
        })

        $('.vacancy__content__row').click(function () {
            if (!$(this).hasClass('open')) {
                let $titleHeight = $(this).find('.vacancy__row__title').height();
                let $contentHeight = $(this).find('.vacancy__row__content').height();
                let $total = $titleHeight + $contentHeight;
                $(this).addClass('open')
                $(this).css({
                    'max-height': $total + 10,
                })
            } else {
                $(this).removeClass('open')
            }
        })

        $('.vacancy__row__title').click(function () {
            let $extra_height = 40;
            if ($(window).width() < 575) {
                $extra_height = 30;
            }
            let $parent = $(this).closest('.vacancy__content__row')
            if ($parent.hasClass('open')) {
                let $titleHeight = $(this).height() + $extra_height;
                $parent.css({
                    'max-height': $titleHeight,
                })
            }
        })


    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
