export const templateVacaturesData = {
    init() {
        // JavaScript to be fired on all pages

        $(function () {
            $(document).on('sf:ajaxfinish', '.searchandfilter', function () {
                let $total = $('.block--vacancy').length;
                let $field = $('.vacature__total');

                $field.text('('+$total+')');

            });
        }(jQuery));

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
